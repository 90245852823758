<template>
  <div class="product-order">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/vendor/orders">Orders</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.number }}</span>
    </div>
    <v-card outlined class="mt-4">
      <div class="pl-md-7 pr-md-6 px-5 pt-8 pb-5 item-content d-flex align-center">
        <div class="mr-4">
          <div class="text-h6 font-weight-bold">{{ data.number }}</div>
          <div class="text--text">Submitted at: {{ new Date(data.submitted_at).toLocaleString('en-GB').split('/').join('.') }}</div>
        </div>
        <v-spacer></v-spacer>
        <div :class="colorStatus(data.status)" class="status-block ml-5">{{ data.status }}</div>
        <div
          v-if="data.status == 'paid'"
          @click="data.status == 'paid' ? updateStatus('delivered') : ''"
          :class="data.status == 'canceled' ? 'gray--text' : 'success--text border-success link'"
          class="status-btn ml-3"
        >
          <v-icon v-if="data.status == 'delivered'" color="success">mdi-check</v-icon>
          Mark as Delivered
        </div>
        <div
          v-if="data.status == 'paid'"
          @click="data.status == 'paid' ? updateStatus('canceled') : ''"
          :class="data.status == 'delivered' ? 'gray--text' : 'error--text border-error link'"
          class="status-btn ml-3"
        >
          <v-icon v-if="data.status == 'canceled'" color="error">mdi-close</v-icon>
          Mark as Canceled
        </div>
      </div>
      <v-divider></v-divider>
      <div class="px-md-8 px-5 pt-4 pb-10 item-content">
        <div class="d-flex flex-sm-row flex-column-reverse mb-5 produst-card" v-for="item in data.items" :key="item.id">
          <div class="d-flex flex-column" style="max-width: 500px">
            <div class="text-h6 font-weight-bold">{{ item.product.title_en }}</div>
            <div class="text--text mt-1 three-rows mb-4">{{ item.product.description_en }}</div>
            <v-spacer></v-spacer>
            <div class="font-weight-bold">SAR {{ item.price / 100 }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-img
            v-if="item.product.picture"
            class="ml-sm-5 mx-auto"
            height="160"
            width="100%"
            max-width="160"
            cover
            :src="item.product.picture.url"
          ></v-img>
        </div>
        <div class="d-flex flex-sm-row flex-column mb-5" v-if="data.customer || data.receiver">
          <div class="mr-sm-3 mb-sm-0 mb-5 width100" v-if="data.customer">
            <div class="font-weight-bold mb-2">Customer details</div>
            <v-card outlined class="px-3 py-4 rounded-lg">
              <div class="d-flex align-center mb-2">
                <div class="mr-4">Full Name</div>
                <v-spacer></v-spacer>
                <div class="text--text">{{ data.customer.first_name }} {{ data.customer.last_name }}</div>
              </div>
              <div class="d-flex align-center mb-2">
                <div class="mr-4">Phone number</div>
                <v-spacer></v-spacer>
                <div class="text--text">{{ data.customer.phone_number }}</div>
              </div>
              <div class="d-flex align-center">
                <div class="mr-4">Email</div>
                <v-spacer></v-spacer>
                <div class="text--text">{{ data.customer.email }}</div>
              </div>
            </v-card>
          </div>
          <div class="ml-sm-3 width100" v-if="data.receiver">
            <div class="font-weight-bold mb-2">Recipient details</div>
            <v-card outlined class="px-3 py-4 rounded-lg">
              <div class="d-flex align-center mb-2">
                <div class="mr-4">Full Name</div>
                <v-spacer></v-spacer>
                <div class="text--text">{{ data.receiver.first_name }} {{ data.receiver.last_name }}</div>
              </div>
              <div class="d-flex align-center mb-2">
                <div class="mr-4">Phone number</div>
                <v-spacer></v-spacer>
                <div class="text--text">{{ data.receiver.phone_number }}</div>
              </div>
              <div class="d-flex align-center">
                <div class="mr-4">Email</div>
                <v-spacer></v-spacer>
                <div class="text--text">{{ data.receiver.email }}</div>
              </div>
            </v-card>
          </div>
        </div>
        <div class="font-weight-bold mb-2" v-if="data.delivery">Delivery address</div>
        <v-card outlined class="px-3 py-4 mb-5 rounded-lg" v-if="data.delivery">
          <div class="d-flex align-center mb-2">
            <div class="mr-4">Country, city</div>
            <v-spacer></v-spacer>
            <div class="text--text">{{ data.delivery.country.name }}, {{ data.delivery.city.name }}</div>
          </div>
          <div class="d-flex align-center mb-2">
            <div class="mr-4">Address</div>
            <v-spacer></v-spacer>
            <div class="text--text">{{ data.delivery.address }}</div>
          </div>
          <div class="d-flex align-center mb-2">
            <div class="mr-4">Delivery date</div>
            <v-spacer></v-spacer>
            <div class="text--text">
              {{ data.delivery.date.split('-').reverse().join('.') }}
              {{ data.delivery.time_from }} {{ data.delivery.time_to ? `- ${data.delivery.time_to}` : '' }}
            </div>
          </div>
          <div class="d-flex align-center">
            <div class="mr-4">Delivery fee</div>
            <v-spacer></v-spacer>
            <div class="text--text">SAR {{ data.delivery.fee / 100 }}</div>
          </div>
        </v-card>
      </div>
      <v-divider></v-divider>
      <div class="pl-md-7 pr-md-10 px-5 pt-6 item-content">
        <div class="d-flex align-center mb-4" v-for="item in data.items" :key="item.id">
          <div class="font-weight-bold text--text">{{ item.product.title_en }}</div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold">SAR {{ item.price / 100 }}</div>
        </div>
        <div class="d-flex align-center mb-4" v-if="data.delivery">
          <div class="font-weight-bold text--text">Delivery fee</div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold">SAR {{ data.delivery.fee / 100 }}</div>
        </div>
      </div>
      <v-divider class="dashad"></v-divider>
      <div class="d-flex align-center pl-md-7 pr-md-10 px-5 pt-4 pb-3 item-content">
        <div class="text-h6 font-weight-bold text--text">Total</div>
        <v-spacer></v-spacer>
        <div class="text-h6 font-weight-bold">SAR {{ data.total / 100 }}</div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      pos: 0,
      error: [],
    };
  },
  created() {
    this.$store.dispatch('getProductOrderItem', this.$route.params.orderId);
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor item saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor item updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    colorStatus(s) {
      if (s == 'paid') {
        return 'primary--text primary lighten-3';
      } else if (s == 'created') {
        return 'warning--text warning lighten-5';
      } else if (s == 'delivered') {
        return 'success--text success lighten-5';
      } else if (s == 'expired') {
        return 'error--text error lighten-5';
      } else {
        return 'gray--text gray lighten-4';
      }
    },
    async updateStatus(s) {
      await this.$store.dispatch('statusProductOrderItem', { id: this.$route.params.orderId, data: { status: s } }).then(() => {
        this.notifi('update');
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.productOrder;
    },
  },
  destroyed() {
    this.$store.dispatch('setProductOrderItem', {});
  },
};
</script>

<style lang="scss">
.product-order {
  .produst-card {
    padding: 20px;
    background: var(--v-gray-lighten4);
    border: 1px solid var(--v-gray-lighten1);
    border-radius: 10px;
    .v-image {
      border-radius: 10px;
    }
  }
  .status-block {
    width: 100px;
    height: 42px;
    line-height: 42px;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    border-radius: 6px;
  }
  .status-btn {
    cursor: auto;
    padding: 10px 12px;
    border-radius: 10px;
    font-weight: 700;
    border: 1px solid var(--v-gray-lighten1);
  }
  .border-success {
    border-color: var(--v-success-base) !important;
  }
  .border-error {
    border-color: var(--v-error-base) !important;
  }
}
</style>
